import React, { useEffect, useRef, useState } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import { useSession } from 'src/sdk/session'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageViewEvent'
import { useCampaignContext } from 'src/contexts/campaign-context'

import storeConfig from '../../store.config'

function Page() {
  const { person } = useSession()
  const [didMount, setDidMount] = useState(false)
  const { sendPageViewEvent } = usePageViewEvent('Login', person)
  const { setCampaignPage } = useCampaignContext()

  setCampaignPage(false)

  const timerRef = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      if (!didMount) {
        sendPageViewEvent()
        setDidMount(true)
      }
    }, 500)
  }, [person])

  useEffect(() => {
    window.insider_object = window.insider_object || {}
    window.insider_object.page = {
      type: 'Login',
      originalType: 'Login',
    }
  }, [])

  useEffect(() => {
    window.location.href = storeConfig.loginUrl
  }, [])

  return (
    <>
      <GatsbySeo noindex nofollow />

      <div>loading...</div>
    </>
  )
}

export const query = graphql`
  query LoginPageQuery {
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export default Page
